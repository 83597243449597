import { ToastId } from '@app/constants/ToastId';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';

export type Meta = Record<string, unknown>;

export interface DeterminateFileProcessing {
    mode: 'determinate';
    message: string;
    value: number;
}

export interface IndeterminateFileProcessing {
    mode: 'indeterminate';
    message?: string;
    value?: 0;
}

export type FileProcessingInfo = IndeterminateFileProcessing | DeterminateFileProcessing;

interface FileProgressBase {
    uploadComplete?: boolean;
    percentage?: number;
    bytesTotal: number | null;
    preprocess?: FileProcessingInfo;
    postprocess?: FileProcessingInfo;
}

export type FileProgressStarted = FileProgressBase & {
    uploadStarted: number;
    bytesUploaded: number;
};
export type FileProgressNotStarted = FileProgressBase & {
    uploadStarted: null;
    bytesUploaded: false;
};
export type FileProgress = FileProgressStarted | FileProgressNotStarted;

export type InternalMetadata = { name: string; type?: string };

export interface UppyFile<M extends Meta, B extends Body> {
    data: Blob | File;
    error?: string | null;
    extension: string;
    id: string;
    isPaused?: boolean;
    isRestored?: boolean;
    isRemote: boolean;
    isGhost: boolean;
    meta: InternalMetadata & M;
    name?: string;
    preview?: string;
    progress: any;
    missingRequiredMetaFields?: string[];
    remote?: {
        body?: Record<string, unknown>;
        companionUrl: string;
        host?: string;
        provider?: string;
        providerName?: string;
        requestClientId: string;
        url: string;
    };
    serverToken?: string | null;
    size: number | null;
    source?: string;
    type: string;
    uploadURL?: string;
    response?: {
        body?: B;
        status: number;
        bytesUploaded?: number;
        uploadURL?: string;
    };
}

type UploadSuccessHandler = () => void;
type UploadErrorHandler = (error: Error) => void;
type UploadProgressHandler = (progress: Record<string, unknown>) => void;

interface ConfigureParameters {
    onUploadSuccess?: (file: any) => Promise<void>;
    onUploadError?: (file: any, error: any) => void;
    onUploadProgress?: (file: any, progress: { bytesUploaded: number }, totalSize: number, loadedIncrement: number) => void;
}

class TusFileUploader {
    private endpoint: string;
    private maxFileSize: number;
    private _environments: string;
    private uppy: any;
    private fileProgressMap: Map<string, number>;

    constructor(endpoint: string, environments: any, headers: Record<string, string>) {
        this.endpoint = endpoint;
        this.maxFileSize = environments.MAX_FILE_SIZE;
        this._environments = environments;
        this.uppy = this.createUppy(headers);
        this.fileProgressMap = new Map();
    }

    createUppy(headers: Record<string, string>) {
        return new Uppy({
            restrictions: {
                maxFileSize: 250 * 1024 * 1024
            },
        }).use(Tus, {
            endpoint: this.endpoint,
            withCredentials: true,
            removeFingerprintOnSuccess: true,
            parallelUploads: 3,
            headers: {
                ...headers,
                'Access-Control-Allow-Origin': '*'
            }
        });
    }

    configureUppy(onUploadSuccess: UploadSuccessHandler, onUploadError: UploadErrorHandler, onUploadProgress: UploadProgressHandler) {
        this.uppy.on('upload-success', async (file: any) => {
            if (onUploadSuccess) onUploadSuccess();
            this.removeUppyFile(file);
        });

        this.uppy.on(
            'upload-error',
            (
                file: UppyFile<Meta, Body> | undefined,
                error: {
                    name: string;
                    message: string;
                    details?: string;
                }
            ) => {
                this.removeUppyFile(file);
                if (onUploadError) onUploadError(error);
            }
        );

        this.uppy.on('upload-progress', (file: UppyFile<Meta, Body>, progress: FileProgressStarted) => {
            const totalSize = this.getTotalSizeOfFiles();
            if (totalSize === 0 || !file) return;
            if (onUploadProgress) {
                onUploadProgress({ uploaded: progress.bytesUploaded, total: progress.bytesTotal });
            }
        });
    }

    removeUppyFile(file: any) {
        if (file) {
            this.uppy.removeFile(file.id);
        }
    }

    cancelAllUploads() {
        this.uppy.cancelAll();
    }

    async uploadFiles(file: any) {
        // this.fileProgressMap.clear();

        // if (files && files.length !== 0) {
        //     const filesArray = Array.from(files);
        // if (this.getTotalSizeOfFiles(files) > this.maxFileSize) {
        //     statusErrorMessageHandler(413,);
        //     return;
        // }

        // filesArray.forEach((file) => {

        let finalFile = new File([''], 'empty.txt', {
            type: 'text/plain',
            lastModified: Date.now()
        });

        if (file !== null) {
            finalFile = file;
        }

        try {
            this.uppy.addFile({
                source: 'file input',
                name: finalFile.name,
                type: finalFile.type,
                data: finalFile
            });
        } catch (err: any) {
            if (err.isRestriction) {
                console.log('Restriction error:', err);
            } else {
                console.error(err);
            }
        }
        // });

        this.uppy.upload();
        // }
    }

    getTotalSizeOfFiles() {
        return this.uppy.getFiles().reduce((total: number, file: UppyFile<Meta, Body>) => (file.size ? total + file.size : 0));
    }
}

export default TusFileUploader;
