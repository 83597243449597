import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import { deleteRequestDto, documentExtractResponse, getPdfAttributeRequestDto, getPdfRequestDto, GetProjectStatsArgs, paginatedDocumentResponse, pdfRequestDto, projectStatsResponse, restoreRequestDto, uuidResponse } from '@app/store/pdf/types';

export const PDF_PATH = 'pdf';
export const PDF_TAG = 'PDF_TAG';

export interface EditorUrlQueryType {
    organizationId: string;
    projectId: string;
    documentId: string;
    isPreview: boolean;
}

const baseQuery = fetchBaseQuery({
    baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api',
    prepareHeaders(headers, api) {
        headers.set('Access-Control-Allow-Origin', '*');
        // Set Content-Type to multipart/form-data only for file upload
        if ((api.endpoint === 'uploadPdf' || api.endpoint === 'resumeDocumentProcess') && headers.get('Content-Type') !== 'application/json') {
            // headers.set('Content-Type', 'multipart/form-data');
        } else {
            headers.set('Content-Type', 'application/json');
        }
        return headers;
    },
    credentials: 'include'
});

export const pdfApi = createApi({
    reducerPath: PDF_PATH,
    tagTypes: [PDF_TAG],
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery,
    endpoints: (builder) => ({
        createUuid: builder.mutation<uuidResponse, pdfRequestDto>({
            query: (requestBody) => ({
                url: 'pdf',
                method: 'POST',
                body: requestBody,
                params: {
                    API_KEY: environments.API_KEY
                }
            })
        }),
        getPdf: builder.query<paginatedDocumentResponse, getPdfRequestDto>({
            query: ({ organizationId, projectId, requestParams, requestBody }) => ({
                url: `organizations/${organizationId}/projects/${projectId}/documents`,
                method: 'POST',
                params: {
                    sort_by: requestParams?.sortBy,
                    sort_order: requestParams?.sortOrder,
                    status: requestParams?.status,
                    query: requestParams?.query,
                    page: requestParams?.page,
                    size: requestParams?.size
                },
                body: requestBody
            }),
            providesTags: [PDF_TAG]
        }),

        getDeletedPdf: builder.query<paginatedDocumentResponse, getPdfRequestDto>({
            query: ({ organizationId, projectId, requestParams, requestBody }) => ({
                url: `organizations/${organizationId}/projects/${projectId}/documents/deleted`,
                method: 'POST',
                params: {
                    sort_by: requestParams?.sortBy,
                    sort_order: requestParams?.sortOrder,
                    status: requestParams?.status,
                    query: requestParams?.query,
                    page: requestParams?.page,
                    size: requestParams?.size
                },
                body: requestBody
            }),
            providesTags: [PDF_TAG]
        }),

        getPdfAttributes: builder.query<Array<String>, getPdfAttributeRequestDto>({
            query: ({ organizationId, projectId, filterBy }) => ({
                url: `organizations/${organizationId}/projects/${projectId}/documents/attributes`,
                method: 'GET',
                params: {
                    filter_by: filterBy
                }
            })
        }),
        extractData: builder.mutation<
            { message: string },
            {
                organizationId: string;
                projectId: string;
                documentId: string;
            }
        >({
            query: (requestBody) => {
                return {
                    url: `organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/documents/${requestBody.documentId}/extract`,
                    method: 'POST'
                };
            }
        }),
        documentExtractStatus: builder.query<
            documentExtractResponse,
            {
                organizationId: string;
                projectId: string;
                documentId: string;
            }
        >({
            query: (requestBody) => {
                return {
                    url: `organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/documents/${requestBody.documentId}/extraction/status`,
                    method: 'GET'
                };
            }
        }),
        uploadPdf: builder.mutation<
            Array<uuidResponse>,
            {
                organizationId: string;
                projectId: string;
                formData: FormData;
            }
        >({
            query: (requestBody) => {
                return {
                    url: `organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/document`,
                    method: 'POST',
                    body: requestBody.formData
                };
            },
            invalidatesTags: [PDF_TAG]
        }),

        resumeDocumentProcess: builder.mutation<
            { message: string },
            {
                organizationId: string;
                projectId: string;
                documentId: string;
                formData: FormData;
            }
        >({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/documents/${requestBody.documentId}/resume`,
                method: 'POST',
                body: requestBody.formData
            })
        }),

        deletePdf: builder.mutation<{ message: String }, deleteRequestDto>({
            query: ({ organizationId, projectId, documentIds }) => ({
                url: `organizations/${organizationId}/projects/${projectId}/documents`,
                method: 'DELETE',
                body: documentIds
            }),
            invalidatesTags: [PDF_TAG]
        }),
        permanentlyDeletePdf: builder.mutation<{ message: String }, deleteRequestDto>({
            query: ({ organizationId, projectId, documentIds }) => ({
                url: `organizations/${organizationId}/projects/${projectId}/documents/permanentlyDelete`,
                method: 'DELETE',
                body: documentIds
            }),
            invalidatesTags: [PDF_TAG]
        }),
        restorePdf: builder.mutation<{ message: String }, restoreRequestDto>({
            query: ({ organizationId, projectId, documentIds }) => ({
                url: `organizations/${organizationId}/projects/${projectId}/documents/restore`,
                method: 'PUT',
                body: documentIds
            }),
            invalidatesTags: [PDF_TAG]
        }),

        getProjectStats: builder.query<projectStatsResponse, GetProjectStatsArgs>({
            query: ({ organizationId, projectId, startDate, endDate }) => ({
                url: `/organizations/${organizationId}/projects/${projectId}/accounting`,
                method: 'GET',
                params: { startDate, endDate }
            })
        }),

        annotatePdf: builder.mutation<any, any>({
            query: ({ organizationId, projectId, documentId, flow, requestBody }) => ({
                url: `/organizations/${organizationId}/projects/${projectId}/documents/${documentId}/annotate`,
                method: 'POST',
                params: {
                    flow: flow
                },
                body: requestBody
            })
        }),

        retryFailedProcess: builder.mutation<any, any>({
            query: ({ organizationId, projectId, documentId }) => ({
                url: `/organizations/${organizationId}/projects/${projectId}/documents/${documentId}/retry`,
                method: 'POST'
            })
        }),
        getEditorUrl: builder.query<any, EditorUrlQueryType>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/documents/${requestBody.documentId}/url`,
                params: {
                    isPreview: requestBody.isPreview
                }
            })
        })
    })
});

export const {
    useCreateUuidMutation,
    useExtractDataMutation,
    useLazyGetPdfAttributesQuery,
    useGetPdfQuery,
    useGetDeletedPdfQuery,
    usePermanentlyDeletePdfMutation,
    useUploadPdfMutation,
    useResumeDocumentProcessMutation,
    useDeletePdfMutation,
    useRestorePdfMutation,
    useAnnotatePdfMutation,
    useRetryFailedProcessMutation,
    useLazyGetEditorUrlQuery,
    useDocumentExtractStatusQuery,
    useGetProjectStatsQuery
} = pdfApi;
