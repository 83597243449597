import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import { OrganizationDto } from '@app/models/dtos/organization-dtos';
import { DeleteMemberRequest, GetMemberRequest, MemberDto, MemberInvitationDto } from '@app/store/project/types';
import { organizationRequest, updateMemberRoleRequest } from '@app/store/organization/types';
import { RemoveInvitationType, SendInvitationType } from '@app/store/invitation/types';
import { GetSettingsResponse } from '@app/components/organization/settings-tab';
import { AuditLogsResponse, LogsType } from '@app/store/project/api';

export const ORGANIZATION_PATH = 'organization';
export const ORGANIZATION_MEMBER_TAG = 'ORGANIZATION_MEMBER';
export const ORGANIZATION_SETTINGS_TAG = 'ORGANIZATION_SETTINGS_TAG';

export interface OcrLicenseDetailsResponse {
    volume: number;
    volumeRemaining: number;
    serialNumber: string;
}

export interface SupportMemberRequest {
    userId: string;
    organizationId: string;
}

export const organizationApi = createApi({
    reducerPath: ORGANIZATION_PATH,
    tagTypes: [ORGANIZATION_MEMBER_TAG, ORGANIZATION_SETTINGS_TAG],
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api',
        credentials: 'include',
        prepareHeaders(headers) {
            headers.set('Access-Control-Allow-Origin', environments.CLIENT_SIDE_ENDPOINT_HOST as string);
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getMineOrganizations: builder.query<Array<OrganizationDto>, void>({
            query: () => ({
                url: '/organizations/mine',
                method: 'GET'
            }),
            providesTags: [ORGANIZATION_SETTINGS_TAG]
        }),
        sendInvitationToMemberFromOrganization: builder.mutation<MemberInvitationDto, SendInvitationType>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/invitation/send`,
                method: 'POST',
                body: {
                    email: requestBody.email,
                    role: requestBody.role
                }
            }),
            invalidatesTags: [ORGANIZATION_MEMBER_TAG]
        }),
        getOrganizationMember: builder.query<Array<MemberDto | MemberInvitationDto>, GetMemberRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/members`,
                method: 'GET',
                params: {
                    query: request.query,
                    sort_by: request.sort_by
                }
            }),
            providesTags: [ORGANIZATION_MEMBER_TAG]
        }),
        deleteOrganizationMember: builder.mutation<void, DeleteMemberRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/members/${request.userIds[0]}`,
                method: 'DELETE'
            }),
            invalidatesTags: [ORGANIZATION_MEMBER_TAG]
        }),
        removeOrganizationInvitation: builder.mutation<any, RemoveInvitationType>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/invitations/${requestBody.invitationToken}`,
                method: 'DELETE'
            }),
            invalidatesTags: [ORGANIZATION_MEMBER_TAG]
        }),
        updateOrganizationInvitationRole: builder.mutation<any, updateMemberRoleRequest>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/invitation/${requestBody.identifier}`,
                method: 'PATCH',
                params: {
                    role: requestBody.role
                }
            }),
            invalidatesTags: [ORGANIZATION_MEMBER_TAG]
        }),
        updateOrganizationMemberRole: builder.mutation<any, updateMemberRoleRequest>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/members/${requestBody.identifier}`,
                method: 'PATCH',
                params: {
                    organizationMemberRole: requestBody.role
                }
            }),
            invalidatesTags: [ORGANIZATION_MEMBER_TAG]
        }),
        getOrganizationLevelConfigs: builder.query<Array<GetSettingsResponse>, { organizationId: string }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/configs`,
                method: 'GET'
            }),
            transformResponse: (response: Array<GetSettingsResponse>) => {
                return response.sort((a, b) => {
                    const keyA = a.settingsName.toLowerCase();
                    const keyB = b.settingsName.toLowerCase();
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }
        }),
        updateOrganizationLevelConfigs: builder.mutation<
            any,
            {
                organizationId: string;
                settingsRequestBodyList: Array<{ settingsId: string; settingsValue: string }>;
            }
        >({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/configs`,
                method: 'PATCH',
                body: requestBody.settingsRequestBodyList
            })
        }),
        getOrganizationAuditLogs: builder.query<AuditLogsResponse, LogsType>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/logs?currentDate=${requestBody.currentDate}`,
                method: 'GET'
            })
        }),
        getOcrLicenseDetails: builder.query<OcrLicenseDetailsResponse, { organizationId: string }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/ocr/license`,
                method: 'GET'
            })
        }),
        updateOrganizationDetails: builder.mutation<any, organizationRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}`,
                method: 'PATCH',
                body: request.requestBody
            }),
            invalidatesTags: [ORGANIZATION_SETTINGS_TAG]
        }),
        addSupportMember: builder.mutation<string, SupportMemberRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/support-member`,
                method: 'POST',
                body: { userId: request.userId }
            }),
            invalidatesTags: [ORGANIZATION_MEMBER_TAG]
        }),
        removeSupportMember: builder.mutation<string, SupportMemberRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/support-member`,
                method: 'DELETE',
                body: { userId: request.userId }
            }),
            invalidatesTags: [ORGANIZATION_MEMBER_TAG]
        })
    })
});

export const {
    useGetMineOrganizationsQuery,
    useRemoveOrganizationInvitationMutation,
    useGetOrganizationMemberQuery,
    useDeleteOrganizationMemberMutation,
    useSendInvitationToMemberFromOrganizationMutation,
    useUpdateOrganizationMemberRoleMutation,
    useUpdateOrganizationInvitationRoleMutation,
    useGetOrganizationLevelConfigsQuery,
    useUpdateOrganizationLevelConfigsMutation,
    useUpdateOrganizationDetailsMutation,
    useLazyGetOrganizationAuditLogsQuery,
    useGetOcrLicenseDetailsQuery,
    useAddSupportMemberMutation,
    useRemoveSupportMemberMutation
} = organizationApi;
